<template>
  <div class="regist" v-loading="loading">
    <el-card v-show="first" class="card1">
      <div class="flex-between-center">
        <h1>在线注册报名</h1>
        <p>
          <a @click="link('Login')">已有账号?点击登录</a>
        </p>
      </div>
      <el-form
        label-position="left"
        label-width="120px"
        :model="form"
        ref="ruleForm"
        :rules="rules"
        hide-required-asterisk
      >
        <el-form-item label="姓名：" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入本人真实姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input
            v-model="form.mobile"
            type="tel"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="短信验证码：" prop="codes">
          <!-- <el-input v-model="form.mobile" placeholder="请输入短信验证码"></el-input> -->
          <div class="flex">
            <el-input
              placeholder="请输入短信验证码"
              v-model="form.codes"
            ></el-input>
            <el-button class="getcode-btn" @click="getCode" :disabled="show">{{
              sendCon
            }}</el-button>
          </div>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            placeholder="请输入密码(8位以上)"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="rpassword">
          <el-input
            v-model="form.rpassword"
            type="password"
            placeholder="请确认密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="推荐人编号:">
          <el-input
            v-model="form.agent"
            placeholder="请输入推荐人（如无，请勿填写）"
            :readonly="readonly"
          ></el-input>
        </el-form-item>
        <el-form-item label class="spe-item" prop="agree">
          <el-checkbox v-model="form.agree" label="1"
            >我同意并遵守
            <a href="/xy" target="view_window"
              >《网培师平台用户协议》</a
            ></el-checkbox
          >
        </el-form-item>
        <el-form-item label class="spe-item">
          <el-button type="danger" class="login" @click="register('ruleForm')"
            >注册</el-button
          >
        </el-form-item>
      </el-form>
      <div></div>
    </el-card>
  </div>
</template>

<script>
// @ is an alias to /src
let _this
export default {
  name: 'Regist',
  data() {
    var validateMobile = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        if (/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 8) {
        callback(new Error('输入密码不少于8位'))
      } else {
        if (this.form.rpassword !== '') {
          this.$refs.ruleForm.validateField('rpassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入验证码'))
      } else if (value.length !== 6) {
        callback(new Error('验证码输入有误!'))
      } else {
        callback()
      }
    }
    var validateagree = (rule, value, callback) => {
      if (value) {
        callback()
      } else {
        callback(new Error('您未同意网培师平台用户协议'))
      }
    }
    return {
      show: false,
      count: '',
      timer: null,
      sendCon: '获取验证码',

      form: {
        name: '',
        mobile: '',
        codes: '',
        agree: '',
        password: '',
        rpassword: '',
        agent: '',
      },
      form1: {},
      first: true,
      res: [
        '个人能力提升',
        '职业背景提升',
        '应企业要求',
        '兴趣爱好',
        '打算进入网培行业',
        '其它',
      ],
      methods: ['音视频直播', '音视频录播', '一对一远程辅导', '其它'],
      rules: {
        mobile: {
          required: true,
          validator: validateMobile,
          message: '请输入手机号',
          trigger: ['blur', 'change'],
        },
        name: {
          required: true,
          message: '请输入本人真实姓名',
          trigger: ['blur', 'change'],
        },
        password: { required: true, validator: validatePass, trigger: 'blur' },

        rpassword: {
          required: true,
          validator: validatePass2,
          trigger: 'blur',
        },

        codes: {
          required: true,
          validator: validateCode,
          trigger: 'blur',
        },
        agree: {
          required: true,
          validator: validateagree,
          message: '您未同意网培师平台用户协议',
          trigger: 'change',
        },
      },
      loading: false,
      readonly: false,
    }
  },
  created() {
    try {
      _this = this
      this.init()
      if (_this.$route.params.agent) {
        document.cookie = 'agent=' + escape(_this.$route.params.agent.trim())
        _this.form.agent = this.getcookie()
        _this.readonly = 'readonly'
      } else if (this.getcookie()) {
        _this.form.agent = this.getcookie()
        _this.readonly = 'readonly'
      }
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    init() {},
    link(name) {
      this.$router.push({ name: name })
    },
    register(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          _this.$http
            .post(process.env.VUE_APP_URL + 'register', this.form)
            .then(data => {
              if (data.data.code == 200) {
                _this.$http
                  .post(process.env.VUE_APP_URL + 'login', {
                    mobile: _this.form.mobile,
                    password: _this.form.password,
                  })
                  .then(data => {
                    _this.loading = true
                    if (data.data.code == 200) {
                      _this.loading = false
                      _this.Storage.setStorage('token', data.data.token)
                      _this.$store.commit('setUser', data.data.data)
                      if (_this.readonly == false) {
                        _this.$router.push({ path: '/' })
                      } else {
                        _this.$router.push({
                          name: 'Traincourse',
                          params: { isfirst: 'first' },
                        })
                      }
                    } else {
                      _this.$message.error(data.data.msg)
                      _this.loading = false
                    }
                  })
                  .catch(err => {
                    _this.$message.error('网络错误，请重试')
                    console.log(err)
                    _this.loading = false
                  })
              } else {
                _this.$message.error(data.data.msg)
                _this.loading = false
              }
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              console.log(err)
              _this.loading = false
            })
        } else {
          return false
        }
      })
    },
    getCode() {
      let mobile = this.form.mobile
      let params = {
        type: 'reg',
        mobile: this.form.mobile,
      }
      if (/^1[3456789]\d{9}$/.test(mobile)) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'sendCode', params)
          .then(data => {
            if (data.data.code == 200) {
              _this.countdown()
              _this.$message.success('验证码已发送')
            } else {
              _this.$message.error(data.data.msg)
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')

            console.log(err)
          })
      } else {
        this.$message.warning('请输入正确格式的手机号')
        return
      }
    },
    countdown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT

        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.show = true
            this.count--
            this.sendCon = this.count + '秒后再次获取'
          } else {
            this.show = false
            clearInterval(this.timer)
            this.timer = null
            this.sendCon = '获取验证码'
          }
        }, 1000)
      }
    },
    getcookie() {
      var arr = document.cookie.split(';')
      for (var i = 0; i < arr.length; i++) {
        var arrcookie = arr[i].split('=')
        if (arrcookie[0].trim() == 'agent') {
          return arrcookie[1]
        }
      }
      return false
    },
  },
}
</script>
<style lang="less" scoped>
.regist {
  font-weight: normal;
  font-size: 16px;
  color: #333;
  a:hover {
    text-decoration: none;
    color: orange;
    cursor: pointer;
  }
  .flex-between-center {
    color: #434346;
    margin-bottom: 50px;
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
  .card1 {
    width: 620px;
    margin: 60px auto;
    /deep/.el-card__body {
      padding: 40px;
    }
    .el-form {
      /deep/.el-form-item__label {
        font-size: 18px;
      }
      /deep/.spe-item .el-form-item__content {
        margin-left: 0 !important;
      }
      .getcode-btn {
        margin-left: 20px;
        border-color: #e10133;
        color: #e10133;
      }
    }
  }
  .card2 {
    width: 70%;
    max-width: 1200px;
    margin: 60px auto;
    /deep/.el-card__body {
      padding: 40px 60px;
    }
  }
  .login {
    background: #e10133;
    width: 300px;
    color: #fff;
    margin-top: 20px;
    margin-left: 120px;
  }
}
</style>
